window.Kavunga = class
  constructor: (input, preview, target, viewMode = 2) ->
    @input = input
    @preview = preview
    @target = target
    @viewMode = viewMode
  readURL: ->
    if @input.files and @input.files[0]
      fileReader = new FileReader
      fileReader.onload = (e) =>
        @setPreview(e.target.result)
      fileReader.readAsDataURL(@input.files[0])
  setPreview: (data) ->
    @preview.attr 'src', data
    @preview.cropper(
      aspectRatio: 16 / 9
      viewMode: @viewMode
      modal: true
      crop: (e) =>
        resize =
          x: e.x
          y: e.y
          width: e.width
          height: e.height
        @target.val JSON.stringify(resize)
    )

window.ImgPreviewer = class
  constructor: (input, preview) ->
    @input = input
    @preview = preview
  readFile: ->
    if @input.files and @input.files[0]
      fileReader = new FileReader
      fileReader.onload = (e) =>
        @preview.attr 'src', e.target.result
      fileReader.readAsDataURL(@input.files[0])